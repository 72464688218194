import { config } from "@imtbl/sdk";
import { immutableZkEvm } from "../integrations/imx/chain";

export const environment = {
    subgraph_url: "https://api.studio.thegraph.com/query/16975/medieval-empires-staking-mumba/version/latest",
    mongo_api_base_url: `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.GATSBY_REALM_APP_ID}/endpoint`,
    mint_api_base_url: "https://mint.api.medievalempires.com",
    nft_meta_base_url: "https://nft.api.medievalempires.com",
    imx_client_id: "EjgLYCJOIyW2yBNmff21Fpe7HklJwfvK",
    imx_environment: config.Environment.PRODUCTION,
    imx_publishable_key: "pk_imapik-CnSTkaOOFQAIClJzOiyV",
    imx_chain: immutableZkEvm,
    chains: [immutableZkEvm],
    wallet_connect_project_id: "68bb307009604d8ba58c5c39fd3137e0"
}   