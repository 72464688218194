//@ts-ignore
import AuditReport from '../documents/audit_report.pdf';

const web_link = "https://medievalempires.com"

export const constants = {
    whitePaper: "https://moongaming.notion.site/Medieval-Empires-Whitepaper-38ed57c1b437410eae3c193af035a931/",
    faq: "https://faq.medievalempires.com/",
    discord: "https://discord.com/invite/ZhRYszk3qF",
    twitter: "https://x.com/MedievalEmpires",
    instagram: "https://www.instagram.com/medievalempirescom/",
    telegram: "https://t.me/Medievalempireschat",
    facebook: "https://www.facebook.com/medievalempirescom",
    youtube: "https://www.youtube.com/channel/UCSFO9rt_lG5nApw645ws5uQ",
    linkedIn: "https://www.linkedin.com/company/moon-gaming/",
    youtube_intro: "https://www.youtube.com/embed/4tqNaJZaegQ?si=Qvj5hkhWlRfpg6Jy&rel=0&controls=1&autoplay=0&mute=0&start=0",
    windows_client: "https://download.apps.medievalempires.com/windows/MedievalEmpiresSetup.exe",
    mac_client: "https://download.apps.medievalempires.com/macos/MedievalEmpires.dmg",
    hyperplay_download: "https://store.hyperplay.xyz/game/medievalempires",
    ultraplay_download: "https://store.ultra.io/en/store/games/medieval_empires/665870fd630cd938414ec32d?utm_source=affiliates&utm_medium=medievalempires&utm_campaign=medievalempires",
    open_sea: "https://opensea.io/collection/medieval-empires-lands",
    help_shift: "https://medievalempires.helpshift.com/",
    staking: "https://meeclaiming.dev.apps.medievalempires.com/",
    bybit: "https://partner.bybit.com/b/MEEBybit",
    mexc: "https://promote.mexc.com/a/SpotMEE",
    quickSwap: "https://quickswap.exchange/#/swap?currency0=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&currency1=0xEB7eaB87837f4Dad1bb80856db9E4506Fc441f3D&swapIndex=0",
    imx_about: "https://www.immutable.com/blog/immutable-passport-the-ultimate-gaming-wallet",
    imx_passport_about: "https://www.immutable.com/blog/immutable-passport",
    loyality_platform_link: "https://loyalty.medievalempires.com",
    sphere_link: "https://sphere.market",
    sphere_env: "immutable",
    assets_uri: "https://medievalempires.com/assets",
    protected_pages_password: "istanbul",
    web_link,
    audit_report: AuditReport,
    main_app_routes: {
        community: `${web_link}/#community`,
        team: `${web_link}/core-team`,
        terms_and_conditions: `${web_link}/terms-and-conditions`,
        launchpad_terms_and_conditions: `${web_link}/terms-and-conditions-genesis-commander-collection`,
        tokenomics: `${web_link}/tokenomics`,
        news: `${web_link}/news`,
        privacy_policy: `$${web_link}/privacy-policy`,
        marketplace: {
            home: `${web_link}/marketplace`,
        },
    }
}